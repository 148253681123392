import { EmployerSalary, EmployerGroup } from "@services/all.services";
import moment from "moment-timezone";
const defaultOptions = {
  includeEmployeInfo: false,
  includeTitle: false,
  total: false,
  linear: null,
};

// @deprecated
export async function createEventFromHours(
  plannedHours,
  options = defaultOptions
) {
  let eventWithoutLunch = [];
  let eventWithLunch = [];
  if (options.total) {
    options.total = 0;
  }
  try {
    if (!plannedHours) {
      throw new Error("Aucun horraire prévu de trouvé");
    }
    for (const plannedHour of plannedHours) {
      /* -- Get needed employe info -- */
      const employeName =
        plannedHour.Employer[0].personnalInformation[0].legalFirstName +
        " " +
        plannedHour.Employer[0].personnalInformation[0].legalName;
      const employePhoto =
        plannedHour.Employer?.[0]?.images?.[0]?.location ||
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

      const event = {
        _id: plannedHour._id,
        user: employeName,
        photo: employePhoto,
        recurrenceId: plannedHour.recurrenceId,
        desc: plannedHour.desc,
        resource: {},
      };

      if (options.includeEmployeInfo) {
        const [groupAndSubGroup, emplacement] = await Promise.all([
          getGroupAndSubgroup(plannedHour.Employer[0]._id),
          getEmplacementByEmployer(plannedHour.Employer[0]._id),
        ]);
        event.groupAndSubGroup = groupAndSubGroup;
        event.emplacement = emplacement;
        event.employeId = plannedHour.Employer[0]._id;
      }

      /* -- find start and end date -- */
      event.start = new Date(plannedHour.start);
      event.end = new Date(plannedHour.end);
      eventWithoutLunch.push({ ...event });

      const dateDifference =
        moment(plannedHour.end).diff(plannedHour.start, "days") + 1;
      const lunchStart = plannedHour?.lunchHour?.startLunch;
      const lunchEnd = plannedHour?.lunchHour?.endLunch;
      const endTime = getTime(plannedHour.end);
      const startLunchTime = getTime(lunchStart);
      const endLunchTime = getTime(lunchEnd);

      for (
        let dateRepetition = 0;
        dateRepetition < dateDifference;
        dateRepetition++
      ) {
        let incrementedDate = moment(plannedHour.start)
          .add(dateRepetition, "days")
          .toDate();

        /* -- all day -- */
        if (!lunchStart) {
          if (dateRepetition === 0) {
            event.start = incrementedDate;
          } else {
            event.start = moment(incrementedDate).hours(0).minutes(0).toDate();
          }
          if (dateRepetition === dateDifference - 1) {
            event.end = moment(incrementedDate)
              .hours(endTime.hours)
              .minutes(endTime.minutes)
              .toDate();
          } else {
            event.end = moment(incrementedDate).hours(23).minutes(59).toDate();
          }
          insertEvent(event, eventWithLunch, options);
          continue;
        }

        /* -- Morning part -- */
        if (dateRepetition === 0) {
          event.start = incrementedDate;
        } else {
          event.start = moment(incrementedDate).hours(0).minutes(0).toDate();
        }

        event.end = moment(incrementedDate)
          .hours(startLunchTime.hours)
          .minutes(startLunchTime.minutes)
          .toDate();
        insertEvent(event, eventWithLunch, options);

        /* -- Afternoon part -- */
        event.start = moment(incrementedDate)
          .hours(endLunchTime.hours)
          .minutes(endLunchTime.minutes)
          .toDate();

        if (dateRepetition === dateDifference - 1) {
          event.end = moment(incrementedDate)
            .hours(endTime.hours)
            .minutes(endTime.minutes)
            .toDate();
        } else {
          event.end = moment(incrementedDate).hours(23).minutes(59).toDate();
        }
        insertEvent(event, eventWithLunch, options);
      }
    }
  } catch (err) {
    console.error(err);
  }

  return {
    eventWithLunch,
    eventWithoutLunch,
    total: typeof options.total === "number" ? options.total : 0,
  };
}

/* -- event utils -- */
function insertEvent(event, eventWithLunch, options) {
  event.eventIndex = getEventIndex(eventWithLunch, event);
  if (options.includeTitle) {
    event.title =
      moment(event.start).format("HH:mm") +
      " - " +
      moment(event.end).format("HH:mm");
  }
  if (typeof options.total === "number") {
    options.total += getTotalFromEvent(event);
  }
  if (options.linear) {
    event.linear = options.linear;
  }
  eventWithLunch.push({ ...event });
}

function getTotalFromEvent(event) {
  const startDate = moment(event.start);
  const endDate = moment(event.end);
  let duration = moment.duration(endDate.diff(startDate));
  return duration.asHours();
}

function getTime(date) {
  return {
    hours: moment(date).hours(),
    minutes: moment(date).minutes(),
  };
}

export function getEventIndex(eventWithLunch, event, index = 0) {
  let dateOnIndex = eventWithLunch.find(
    (e) =>
      e.eventIndex === index &&
      (moment(event.start).isBetween(e.start, e.end, null, "[]") ||
        moment(event.end).isBetween(e.start, e.end, null, "[]") ||
        moment(e.start).isBetween(event.start, event.end, null, "[]") ||
        moment(e.end).isBetween(event.start, event.end, null, "[]"))
  );
  if (dateOnIndex) {
    return getEventIndex(eventWithLunch, event, index + 1);
  }
  return index;
}

/* -- load employe data -- */
async function getEmplacementByEmployer(employeId) {
  let emplacement = null;
  await EmployerSalary.getByEmployer(employeId)
    .then((result) => {
      if (!result) {
        throw new Error("Erreur chargement emplacement");
      }
      emplacement = result?.job?.[0]?.emplacement;
    })
    .catch((err) => console.error(err));
  return emplacement;
}

async function getGroupAndSubgroup(employeId) {
  let output = [];
  try {
    const empoyerGroupes = (await EmployerGroup.listByEmployer(employeId)).data
      ?.data;
    if (!empoyerGroupes) {
      throw new Error("Aucune donnée trouvée");
    }

    for (const employeGroup of empoyerGroupes) {
      let Employergroupe = employeGroup.Groupe[0];
      if (Employergroupe) {
        if (employeGroup.SousGroupe?.length) {
          const groupe = output.find((g) => g._id === Employergroupe._id);
          if (groupe) {
            groupe.subgroups.push(employeGroup.SousGroupe[0]);
          } else {
            output.push({
              _id: Employergroupe._id,
              title: Employergroupe.title,
              subgroups: [employeGroup.SousGroupe[0]],
            });
          }
        } else {
          output.push({
            _id: Employergroupe._id,
            title: Employergroupe.title,
            subgroups: [],
          });
        }
      }
    }
    return output;
  } catch (err) {
    console.error(err);
  }
  return output;
}
