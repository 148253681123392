import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";

import { Listbox, Transition } from "@headlessui/react";
import { Caret } from "@routes/IconeSvg";
//SvgResetFilter
import { SketchPicker } from "react-color";

import { presetColors } from "@helpers/_configs";
import { getRGBA, rgbToHex } from "@helpers/_functions";

/**
 * @typedef {Object} Props
 * @property {string} name
 * @property {boolean=} invalid
 * @property {boolean=} readOnly
 * @property {string} customListHeight
 * @property {any[]} employeeList
 * @property {string} anotherParentClass
 * @property {string} topView
 * @property {any} value
 * @property {string=} placeholder
 * @property {string} classParentList
 * @property {string} label
 * @property {any} classLabel
 * @property {string} otherPlaceholder
 * @property {(...args: any) => void} setValue
 */

/**
 *
 * @param {Props} props
 * @returns
 */
export default function ListboxColorPicker(props) {
  const {
    name,
    invalid = false,
    readOnly = false,
    customListHeight = "arh-max-h-60",
    employeeList,
    anotherParentClass,
    topView,
    value: defaultValue,
    placeholder,
  } = props;

  const [colorValue, setColorValue] = useState({
    valueHexadecimale: "",
    rgba: "",
  });

  useEffect(() => setColorValue(defaultValue), [defaultValue]);

  const buttonRef = useRef(null);
  const onPickColor = useCallback((value) => {
    let colorPicked = value.rgb;
    let color = {
      valueHexadecimale: "",
      rgba: "",
    };
    color.rgba = getRGBA(colorPicked.r, colorPicked.g, colorPicked.b, 1);
    color.valueHexadecimale = rgbToHex(
      colorPicked.r,
      colorPicked.g,
      colorPicked.b,
    );
    setColorValue(color);
  }, []);

  function onSaveClicked() {
    props.setValue(colorValue);
    closeListBox();
  }

  function onCancelClicked() {
    setColorValue(defaultValue);
    closeListBox();
  }

  function closeListBox() {
    buttonRef.current && buttonRef.current.click();
  }

  return (
    <Listbox
      value={colorValue}
      name={name}
      disabled={readOnly}
      onChange={() => {}}
    >
      {({ open }) => (
        <div className={props.classParentList + " arh-relative arh-w-full"}>
          {props.label ? (
            <label
              className={`
                  arh-mb-1 arh-w-full arh-text-left arh-font-poppins arh-text-xs arh-font-medium
                  ${props?.classLabel} 
                  ${invalid ? " arh-text-[#F60A20] " : " arh-text-black "} 
              `}
            >
              {props?.label}
            </label>
          ) : (
            ""
          )}
          <Listbox.Button
            className={`arh- arh-relative arh-h-11 arh-w-full arh-cursor-pointer arh-rounded arh-border arh-border-solid arh-py-3 arh-pl-6 arh-pr-8 arh-text-left arh-font-poppins arh-text-xs arh-font-normal focus:arh-outline-none focus-visible:arh-border-[#ECECEC] focus-visible:arh-ring-2 focus-visible:arh-ring-[#ECECEC] focus-visible:arh-ring-opacity-75 focus-visible:arh-ring-offset-2 focus-visible:arh-ring-offset-greyColor ${
              !invalid
                ? " arh-border-[#ECECEC] arh-bg-white placeholder:arh-text-[#8B8F91] "
                : " arh-border-[#F60A20] arh-bg-[#FFF3F4] arh-text-[#F60A20] placeholder:arh-text-[#F60A20]"
            } ${readOnly && "arh-bg-[#e9ecef]"}`}
            as="div"
            ref={buttonRef}
          >
            <div className="arh-flex arh-items-center arh-space-x-3">
              {colorValue?.valueHexadecimale && (
                <span
                  className={`arh-h-3 arh-w-3 arh-rounded-full`}
                  style={{ backgroundColor: colorValue?.valueHexadecimale }}
                ></span>
              )}

              <input
                className={`arh-block arh-w-full arh-max-w-full arh-truncate arh-text-ellipsis arh-border-0 arh-text-darkColor ${
                  props.otherPlaceholder
                } arh-pointer-events-none ${readOnly && "arh-bg-[#e9ecef]"} ${
                  invalid
                    ? "arh-text-[#F60A20] placeholder:arh-bg-[#FFF3F4] placeholder:arh-text-[#F60A20]"
                    : "placeholder:arh-text-[#8B8F91] "
                }`}
                type="text"
                placeholder={placeholder}
                value={colorValue?.valueHexadecimale || ""}
              />
            </div>
            <span className="arh-pointer-events-none arh-absolute arh-inset-y-0 arh-right-0 arh-flex arh-items-center arh-pr-18">
              <Caret
                className={`${open ? "arh-rotate-90" : "arh-rotate-0"}`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="arh-transition arh-ease-in arh-duration-100"
            leaveFrom="arh-opacity-100"
            leaveTo="arh-opacity-0"
          >
            <Listbox.Options
              className={`
                ${employeeList ? "arh-max-h-[15.7rem]" : customListHeight}
                arh-absolute arh-z-[6]  arh-mt-1 arh-w-full  arh-overflow-y-auto arh-overflow-x-hidden arh-rounded arh-bg-white arh-px-4 arh-py-6 arh-text-xs arh-shadow-xs 3xl:arh-w-[120%] desktop-l:arh-w-[100%]
                ${anotherParentClass} 
                ${customListHeight !== "arh-max-h-60" && "arh-overflow-y-auto"}
                ${topView && "arh-bottom-full arh-mb-1"}
              `}
            >
              <div
                key="ratingKey"
                className={`arh-relative arh-cursor-pointer arh-select-none arh-list-none  arh-truncate arh-font-poppins arh-text-xs arh-font-normal arh-text-darkColor ${
                  employeeList ? "arh-mb-1" : "arh-py-1.5"
                }`}
              >
                <div className="modalColorPickerDropdown arh-w-full">
                  <SketchPicker
                    color=""
                    onChangeComplete={onPickColor}
                    presetColors={presetColors}
                  />
                  <div className="arh-mt-6 arh-flex arh-w-full arh-items-center arh-justify-between arh-p-3">
                    <button
                      className="arh-h-8 arh-w-2/5 arh-rounded-sm arh-border arh-border-solid !arh-border-[#B0BEC5] arh-bg-transparent arh-font-poppins arh-text-xs arh-font-medium arh-text-black"
                      onClick={onCancelClicked}
                    >
                      Annuller
                    </button>
                    <button
                      className="blueBtn arh-h-8 arh-w-2/5 arh-rounded-sm arh-border arh-border-solid arh-border-[#e5e5e5] arh-font-poppins arh-text-xs"
                      onClick={onSaveClicked}
                    >
                      Enregistrer
                    </button>
                  </div>
                </div>
              </div>
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
